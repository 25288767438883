/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import 'typeface-lato';
import 'typeface-poppins';
import 'typeface-raleway';

import './src/styles/global.css';
